import _process from "process";
var exports = {};
var process = _process;

var getMilliseconds = function () {
  if (typeof process !== "undefined" && process.hrtime) {
    var hrtime = process.hrtime();
    var seconds = hrtime[0];
    var nanoseconds = hrtime[1];
    return seconds * 1000 + Math.floor(nanoseconds / 1000000);
  }

  return new Date().getTime();
};

exports = getMilliseconds;
export default exports;